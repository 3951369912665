import styles from "../../styles/form/dropdown.module.css";

export function Dropdown({ title, type, options, onChange }) {
  return (
    <>
      <div className={styles["sec-center"]}>
        <input
          className={styles["dropdown"]}
          type="checkbox"
          id={title + "_dropdown"}
          name="dropdown"
          style={{ display: "none" }}
        />
        <label className={styles["for-dropdown"]} htmlFor={title + "_dropdown"}>
          {title}
          <i className="bx bx-caret-down"></i>
        </label>
        <div className={styles["section-dropdown"]}>
          {options.map((option) => (
            <>
              <input
                className={styles["dropdown-sub"]}
                type="checkbox"
                id="dropdown-sub"
                name="dropdown-sub"
                style={{ display: "none" }}
              />
              <label
                className={styles["for-dropdown-sub"]}
                htmlFor="dropdown-sub"
                onClick={() => {
                  onChange(option);
                  document.getElementById(title + "_dropdown").checked = false;
                }}
              >
                {option} <i className={styles["uil uil-plus"]}></i>
              </label>
            </>
          ))}
        </div>
        <small>Selected {type}</small>
      </div>
    </>
  );
}
