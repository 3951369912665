"use client";
import Image from "next/image";
import { useRouter } from "next/navigation";
import "boxicons/css/boxicons.min.css";
import headerStyles from "../../styles/partials/header.module.css";

export default function Header() {
  const router = useRouter();
  return (
    <div className={headerStyles["container"]}>
      <Image
        src={"/images/logo-complete.png"}
        alt={"RestructAI Logo"}
        layout="fill"
        className={headerStyles["image"]}
        draggable="false"
        loading={"eager"}
      />
    </div>
  );
}
