import { useRef, useEffect, useState } from "react";
import Head from "next/head";
import { Gemunu_Libre } from "next/font/google";
const logoFont = Gemunu_Libre({
  weight: "400",
  style: "normal",
  subsets: ["latin"],
  display: "swap",
});

import FeatureSummary from "../components/partials/FeatureSummary";
import TasksGraphic from "../components/graphics/Tasks";
import Modal from "../components/form/Modal";
import CTA from "../components/form/CTA";
import FAQ from "../components/faq/Index";
import ListsGraphic from "../components/graphics/Lists";
import UserDashboardGraphic from "../components/graphics/User-Dashboard";
import HarbourDashboardGraphic from "../components/graphics/Harbour-Dashboard";
import Features from "../components/partials/Features";
import FormInput from "../components/form/Input";
import FormButton from "../components/form/Button";
import { isEmail } from "../lib/functions/helpers";
import sendFetchRequest from "../lib/functions/fetch";

import "boxicons/css/boxicons.min.css";
import splashStyles from "../styles/splash.module.css";
import Header from "../components/partials/Header";
import ctaStyles from "../styles/form/cta.module.css";

export default function Landing() {
  const splashRef = useRef();
  const backWaveRef = useRef();
  const middleWaveRef = useRef();

  function parallax(element, speed) {
    if (element != null)
      element.style.transform =
        "translateY(" + window.pageYOffset * speed + "px)";
  }

  const infoParallax = () => parallax(splashRef.current, 0.35);
  const backWaveParallax = () => parallax(backWaveRef.current, 0.25);
  const midWaveParallax = () => parallax(middleWaveRef.current, 0.15);

  useEffect(() => {
    if (window.innerWidth > 850) {
      window.addEventListener("scroll", infoParallax);
      window.addEventListener("scroll", backWaveParallax);
      window.addEventListener("scroll", midWaveParallax);

      return () => {
        window.removeEventListener("scroll", infoParallax);
        window.removeEventListener("scroll", backWaveParallax);
        window.removeEventListener("scroll", midWaveParallax);
      };
    }
  }, []);

  // Splash CTA (has to be seperate to prevent transform breaking fixed position)
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Head>
        <title>RestructAI</title>
        <meta
          property="og:title"
          content="RestructAI, AI-powered insights for your business."
        />
        <meta
          name="description"
          content="Concise, actionable insights for you, powered by AI."
        />
        <meta
          property="og:description"
          content="Concise, actionable insights for you, powered by AI."
        />
      </Head>
      <Header />
      <div
        className={"container-full " + splashStyles["container-full-splash"]}
      >
        <div
          className={
            "container-partial " + splashStyles["container-partial-splash"]
          }
          ref={splashRef}
        >
          <div className={splashStyles["splash-info"]}>
            <div className={splashStyles["splash-logo"]}>
              <div className={splashStyles["splash-logo-image"]}>
                <img src="/images/rr_icon.png" alt="RestructAI Logo" />
              </div>
              <div
                className={
                  splashStyles["splash-logo-text"] + " " + logoFont.className
                }
              >
                RESTRUCT<span>AI</span>
              </div>
            </div>
            <h1 className={splashStyles["heading"]}>
              Restructuring&nbsp;<span>Insights,&nbsp;</span>
              straight to your&nbsp;<span>Inbox</span>
            </h1>
          </div>
        </div>
        <CTA independent={true} />
        <div
          className={
            splashStyles["wave"] + " " + splashStyles["wave-background"]
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            viewBox="0 0 1440 320"
            ref={backWaveRef}
          >
            <path
              fill="#0d3772"
              fillOpacity="1"
              d="M0,32L34.3,32C68.6,32,137,32,206,26.7C274.3,21,343,11,411,26.7C480,43,549,85,617,96C685.7,107,754,85,823,69.3C891.4,53,960,43,1029,64C1097.1,85,1166,139,1234,170.7C1302.9,203,1371,213,1406,218.7L1440,224L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div
          className={
            splashStyles["wave"] + " " + splashStyles["wave-middleground"]
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            viewBox="0 0 1440 320"
            ref={middleWaveRef}
          >
            <path
              fill="#d7b162"
              fillOpacity="1"
              d="M0,64L48,69.3C96,75,192,85,288,106.7C384,128,480,160,576,144C672,128,768,64,864,58.7C960,53,1056,107,1152,128C1248,149,1344,139,1392,133.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div
          className={
            splashStyles["wave"] + " " + splashStyles["wave-foreground"]
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#000000"
              fillOpacity="1"
              d="M0,192L60,202.7C120,213,240,235,360,229.3C480,224,600,192,720,176C840,160,960,160,1080,181.3C1200,203,1320,245,1380,266.7L1440,288L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>

      <Features />

      {/* <FAQ /> */}
    </>
  );
}
