import { useEffect, useRef, useState } from "react";

import featureStyles from "../../styles/partials/features.module.css";

export default function Features() {
  const featuresIntersectionDetectorRef = useRef();
  const [featuresIntersection, setFeaturesIntersection] = useState();

  const getFeatureStyles = () => {
    if (!featuresIntersection)
      return "container-partial " + featureStyles["container-partial"];
    return (
      "container-partial " +
      featureStyles["container-partial"] +
      " " +
      featureStyles["animation"]
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (!featuresIntersection && entry.isIntersecting) {
        setFeaturesIntersection(true);
        observer.disconnect();
      }
    });
    observer.observe(featuresIntersectionDetectorRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className={"container-full " + featureStyles["container-full"]}>
      <div className={getFeatureStyles()}>
        <div
          className={featureStyles["intersection-dectector"]}
          ref={featuresIntersectionDetectorRef}
        ></div>
        <h2> Optimising your information consumption</h2>
        <div className={featureStyles["container-partial-list"]}>
          <div className={featureStyles["feature"]}>
            <i className="bx bxs-calendar"></i>
            <p>
              Daily restructuring insights, derived from an extensive range of
              APIs
            </p>
          </div>
          <div className={featureStyles["feature"]}>
            <i className="bx bxs-cloud"></i>
            <p>Specialised Artificial Intelligence pipeline</p>
          </div>
          <div className={featureStyles["feature"]}>
            <i className="bx bxs-timer"></i>
            <p>
              Access real-time insights at any place, any time, via web, mail or
              app
            </p>
          </div>
        </div>
        <h2>What are the benefits?</h2>
        <div className={featureStyles["container-partial-list"]}>
          <div className={featureStyles["feature"]}>
            <i className="bx bxs-spreadsheet"></i>
            <p>Stay informed, never miss a crucial development</p>
          </div>
          <div className={featureStyles["feature"]}>
            <i className="bx bx-trending-up"></i>
            <p>
              Optimise your time and effort spent analysing industry
              developments{" "}
            </p>
          </div>
          <div className={featureStyles["feature-light"]}>
            <i className="bx bxs-user">
              {" "}
              <div className={featureStyles["feature-icon-text"]}>
                {" "}
                Students
              </div>
            </i>
            <p>Leverage content in academic work and interview processes</p>
          </div>
        </div>
      </div>
    </div>
  );
}
