import Link from "next/link";

import FAQCard from "./Card";

import faqStyles from "../../styles/faq/index.module.css";

export default function FAQ() {
  return (
    <div className={"container-full " + faqStyles["container-full"]}>
      <div className={"container-partial " + faqStyles["container-partial"]}>
        <h2>What we do</h2>
        <div className={faqStyles["container-discussion"]}>
          <div className={faqStyles["discussion-column"]}>
            <FAQCard question={"Step 1"}>
              We have reliable sources that we hav selected to feed into our AI
              system to track and monitor.
            </FAQCard>
            <FAQCard question={"Step 2"}>
              We extrapolate restructuring news from these selected sources via
              our API system, developed through machine learning and buzzword
              identification.
            </FAQCard>
            <FAQCard question={"Step 3"}>
              We summarise this information into news articles and daily emails
              for you to digest at ease, eliminating the risk that you miss a
              key development.
            </FAQCard>
            <FAQCard question={"Step 4"}>
              For the moment we are focused on just the UK area and UK regions
              to ensure a precise launch. Plans for expansion and evolution into
              foreign markets.
            </FAQCard>
          </div>
        </div>
      </div>
    </div>
  );
}
