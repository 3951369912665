import { useEffect, useState } from "react";

import FormInput from "./Input";
import FormButton from "./Button";
import Modal from "./Modal";

import ctaStyles from "../../styles/form/cta.module.css";

import { isEmail } from "../../lib/functions/helpers";
import sendFetchRequest from "../../lib/functions/fetch";
import { Dropdown } from "./Dropdown";

export default function CTA({ independent }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedProfession, setSelectedProfession] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [regionTitle, setRegionTitle] = useState("Priority region");
  const [professionTitle, setProfessionTitle] = useState("I am a...");
  const [modalHeading, setModalHeading] = useState("");
  const [modalBody, setModalBody] = useState("");

  useEffect(() => {
    if (selectedRegion) setRegionTitle(selectedRegion);
  }, [selectedRegion]);
  useEffect(() => {
    if (selectedProfession) setProfessionTitle(selectedProfession);
  }, [selectedProfession]);

  async function handleSubmit(e) {
    e.preventDefault();
    const email = e.target.email.value;
    const profession = selectedProfession;
    const region = selectedRegion;

    // Check that all fields are filled in
    if (!profession || !region) {
      setModalHeading("Error");
      setModalBody("Please fill in all fields.");
      setShowModal(true);
      return;
    }

    if (email.length > 0 && isEmail(email)) {
      const res = await sendFetchRequest("/api/join/", "POST", {
        email,
        profession,
        region,
      });
      e.target.email.value = null;

      if (res.message === "ok") {
        setModalHeading("Thank you!");
        setModalBody("Your interest has been recorded.");
      } else {
        setModalHeading("Error");
        setModalBody(res.message);
      }
      setShowModal(true);
    }
  }

  if (!independent)
    return (
      <form onSubmit={handleSubmit} className={ctaStyles["container-form"]}>
        <div className={ctaStyles["container-form-input"]}>
          <FormInput
            type={"text"}
            name={"email"}
            placeholder="Enter email address..."
            required={true}
          />
          <small>Get a special offer at launch by signing up now.</small>
        </div>
        <div className={ctaStyles["container-form-button"]}>
          <FormButton type={"submit"} light={true}>
            Sign Up
          </FormButton>
          <Modal
            show={showModal}
            onClose={() => setShowModal(false)}
            heading={modalHeading}
            body={modalBody}
            buttonValue={"Okay!"}
          />
        </div>
      </form>
    );
  else
    return (
      <div className={"container-full " + ctaStyles["container-full"]}>
        <div className={"container-partial " + ctaStyles["container-partial"]}>
          <div className={ctaStyles["container-content"]}>
            <div className={ctaStyles["container-info"]}>
              <h2 className={ctaStyles["heading"]}>Join the waiting list</h2>
              <form
                onSubmit={handleSubmit}
                className={ctaStyles["container-form"]}
              >
                <div className={ctaStyles["container-form-input"]}>
                  <FormInput
                    type={"text"}
                    name={"email"}
                    placeholder="Enter email address..."
                    required={true}
                    secondary={true}
                  />
                  <small>Selected email</small>
                </div>
                <div className={ctaStyles["container-form-dropdowns"]}>
                  <Dropdown
                    title={professionTitle}
                    options={["Student", "Professional", "Other"]}
                    onChange={(option) => setSelectedProfession(option)}
                    type={"category"}
                  />
                  <Dropdown
                    title={regionTitle}
                    options={[
                      "London",
                      "NE England",
                      "NW England",
                      "Yorkshire",
                      "E Midlands",
                      "W Midlands",
                      "SE + E England",
                      "SW England",
                      "Scotland",
                      "NI",
                      "Wales",
                    ]}
                    onChange={(option) => setSelectedRegion(option)}
                    type={"region"}
                  />
                </div>

                <div className={ctaStyles["container-form-button"]}>
                  <FormButton type={"submit"} secondary={false}>
                    Sign Up
                  </FormButton>
                  <Modal
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    heading={modalHeading}
                    body={modalBody}
                    buttonValue={"Okay"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
}
